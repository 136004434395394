import SvgIcon from '@mui/material/SvgIcon'
import { ReactElement } from 'react'

export function FacebookIcon(): ReactElement {
  return (
    <SvgIcon sx={{ height: 16 }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1 15H2.9C1.85066 15 1 14.1493 1 13.1V2.9C1 1.85066 1.85066 1 2.9 1H13.1C14.1493 1 15 1.85066 15 2.9V13.1C15 14.1493 14.1493 15 13.1 15Z"
          fill="#1977F3"
        />
        <path
          d="M9.09786 6.6532V8.04452H11.0839L10.7277 10.0605H9.09786V15H6.90668V10.0605H5.12793V8.04452H6.90668V6.58413C6.90668 6.51058 6.90967 6.4376 6.91536 6.36498L6.90668 6.37021V6.1156C6.90907 5.94729 6.93831 5.14087 7.58992 4.47823C8.25127 3.80568 9.07248 3.77063 9.23728 3.76702C9.58427 3.74254 9.96485 3.73283 10.374 3.74796C10.6295 3.75741 10.8723 3.77567 11.1013 3.8C11.1009 4.4123 11.1005 5.0246 11.1002 5.63689C11.0198 5.63596 10.9209 5.63894 10.7909 5.63689C10.6808 5.63512 10.5387 5.63288 10.4256 5.63689C10.2651 5.64258 9.83623 5.6578 9.56622 5.837C9.4391 5.92128 9.33802 6.03235 9.33802 6.03235C9.28491 6.09031 9.25626 6.1324 9.25626 6.1324C9.25225 6.13819 9.24842 6.14397 9.24469 6.14976C9.14724 6.29947 9.09786 6.47493 9.09786 6.6532Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  )
}
