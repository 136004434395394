import SvgIcon from '@mui/material/SvgIcon'
import { ReactElement } from 'react'

export function GoogleIcon(): ReactElement {
  return (
    <SvgIcon sx={{ height: 16 }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.8638 6.62904H14.3V6.59999H7.99999V9.39999H11.956C11.3789 11.0299 9.82804 12.2 7.99999 12.2C5.68054 12.2 3.8 10.3194 3.8 7.99999C3.8 5.68054 5.68054 3.8 7.99999 3.8C9.07064 3.8 10.0447 4.2039 10.7863 4.86364L12.7663 2.8837C11.5161 1.71855 9.84379 1 7.99999 1C4.13425 1 1 4.13425 1 7.99999C1 11.8657 4.13425 15 7.99999 15C11.8657 15 15 11.8657 15 7.99999C15 7.53064 14.9517 7.07249 14.8638 6.62904Z"
          fill="#FFC107"
        />
        <path
          d="M1.80713 4.74184L4.10698 6.42849C4.72927 4.88779 6.23637 3.8 8.00002 3.8C9.07067 3.8 10.0447 4.20389 10.7864 4.86364L12.7663 2.8837C11.5161 1.71855 9.84382 1 8.00002 1C5.31132 1 2.97963 2.51795 1.80713 4.74184Z"
          fill="#FF3D00"
        />
        <path
          d="M7.99999 15C9.80809 15 11.451 14.308 12.6931 13.1828L10.5266 11.3495C9.80023 11.9019 8.9126 12.2007 7.99999 12.2C6.17929 12.2 4.63335 11.039 4.05095 9.41888L1.76825 11.1776C2.92675 13.4446 5.27944 15 7.99999 15Z"
          fill="#4CAF50"
        />
        <path
          d="M14.8638 6.62904H14.3V6.59999H8V9.39998H11.956C11.68 10.1757 11.1827 10.8536 10.5256 11.3498L10.5266 11.3491L12.6931 13.1824C12.5398 13.3217 15 11.5 15 7.99998C15 7.53064 14.9517 7.07249 14.8638 6.62904Z"
          fill="#1976D2"
        />
      </svg>
    </SvgIcon>
  )
}
