import { createSvgIcon } from '@mui/material/utils'

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 3.4a8.6 8.6 0 1 0 0 17.2 8.6 8.6 0 0 0 0-17.2ZM1.4 12C1.4 6.146 6.146 1.4 12 1.4c5.854 0 10.6 4.746 10.6 10.6 0 5.855-4.746 10.6-10.6 10.6-5.854 0-10.6-4.745-10.6-10.6Z"
  />,
  'Circle'
)
